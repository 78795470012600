import Swiper, { Autoplay, EffectFade } from 'swiper';
Swiper.use([Autoplay, EffectFade]);

export function slider() {
  const swiper = new Swiper('.swiper-container', {
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    effect: 'fade',
  });
}
